:root {
	--backgroundColor: rgb(229, 231, 235);
	--primaryColor: #191a1b;
	--textColorSecondary: rgba(0, 0, 0, 0.45);
	--weiBrandLightBlue: rgb(0, 137, 224);
	--weiBrandLightGreen: rgb(79, 153, 128);
	--weiBrandTeal: rgb(7, 167, 189);
	--weiBrandDarkBlue: rgb(0, 98, 152);
	--weiBrandNeutralBlack: rgb(0, 0, 0);
	--weiBrandNeutralDarkGray: rgb(67, 67, 67);
	--weiBrandNeutralGray: rgb(153, 153, 153);
	--weiBrandNeutralLightGray: rgb(192, 192, 192);
	--weiBrandNeutralWhite: rgb(255, 255, 255);
}
