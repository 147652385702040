@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
	.border-t-transparent {
		border-top-color: transparent;
	}
}

@import 'antd/dist/antd.css';

/* .ant-layout-header {
	@apply flex flex-row items-center;
} */
/* .ant-transfer-operation > button {
	@apply hidden !important;
} */

.anticon {
	vertical-align: middle;
}

.ant-collapse-expand-icon {
	@apply pl-4;
}

.ant-page-header-heading {
	@apply flex flex-row w-full;
}

.ant-page-header-content {
	@apply pt-0;
}

.site-page-header {
	@apply flex flex-row items-center;
}

.logo {
	float: left;
	width: 120px;
	height: 31px;
	/* margin: 16px 24px 16px 0; */
	@apply bg-siteBackground !important;
}

.site-page-header * {
	@apply text-slate-500 uppercase !important;
}

.site-sub-header {
	@apply text-secondary uppercase font-semibold underline !important;
}

.site-header-background {
	@apply bg-white !important;
}

.ant-btn {
	@apply rounded-lg;
}
.ant-input-search-button {
	@apply border-none !important;
}

.ant-tag {
	@apply border-0;
}

.primary-btn {
	@apply bg-primary font-bold;
}
.ant-btn-primary {
	@apply bg-sky-500 text-white font-bold !important;
}

.page-sub-title {
	@apply mb-5 font-bold text-2xl text-slate-400;
}

button:disabled,
button[disabled] {
	@apply bg-slate-200 text-slate-400 !important;
}
.danger-btn {
	@apply bg-red-500 font-bold border-red-600 text-white !important;
}
.ant-btn-danger {
	@apply bg-red-500 text-white font-bold !important;
}

.success-btn {
	@apply bg-green-600 text-white font-bold !important;
}

.ant-popover-disabled-compatible-wrapper {
	@apply bg-opacity-0 !important;
}

.warning-btn {
	@apply bg-accent text-white !important;
	/* @apply bg-amber-500 text-amber-900 font-bold !important; */
}

.ant-select-selection-overflow {
	min-height: 40px;
	max-height: 40px;
	overflow-y: clip;
}

.ant-select-selection-placeholder {
	@apply text-header pl-2 font-semibold !important;
}

.standard-form div div.label {
	@apply pr-10 font-bold text-lg w-48 text-header;
}

.standard-form div div.value {
	@apply pr-10 font-bold text-lg w-full;
}

.tool-icons {
	@apply w-8 h-8 inline-block;
}

.ant-tool-icons {
	@apply text-2xl -mt-0.5 ml-1 inline-block;
}

.ant-transfer-list-content {
	@apply bg-white;
}

.ant-menu-vertical .ant-menu-item {
	margin-bottom: 0px !important;
}

.ant-menu-item {
	text-align: center !important;
	vertical-align: middle !important;
	@apply h-14 pt-2 !important;
}

.nav-bar-menu .ant-menu-item-selected {
	@apply h-14 text-white bg-secondary pt-2 !important;
}

.ant-table-column-has-sorters,
.ant-table-thead .ant-table-cell {
	@apply bg-header text-white !important;
}

.ant-table-thead .ant-table-cell {
	@apply uppercase text-sky-300 font-bold !important;
}

.ant-empty {
	@apply min-h-[480px] !important;
}

.ant-table-content {
	@apply min-h-fit !important;
}

#custom-table .ant-table-thead .ant-table-cell {
	@apply bg-header opacity-100 !important;
}

#custom-table .embed-table .ant-table-thead .ant-table-cell {
	@apply bg-slate-300 text-slate-400 opacity-100 !important;
}

#custom-sub-table .ant-table-thead .ant-table-cell {
	@apply bg-slate-300 opacity-100 text-black !important;
}
.ant-table-cell {
	@apply py-3 text-sm !important;
}

.ant-input-wrapper {
	@apply border border-slate-200 rounded-lg overflow-hidden;
}
.ant-transfer-list-content-item-text {
	@apply text-base pl-4 !important;
}
.batch-select {
	width: 50%;
}
.anticon-search {
	@apply text-white hover:text-header active:text-header focus:text-header;
}

.ant-input-group-addon {
	@apply bg-header border-b-2 border-blue-600;
}

.ant-table-filter-column *,
.ant-table-column-sorters * {
	@apply z-0 !important;
}

.ant-table table {
	@apply text-xs !important;
}

.ant-table-tbody > tr > td {
	/* word-wrap: break-word; */
	@apply text-sm !important;
}
.ant-collapse-header-text {
	@apply text-slate-50 p-0 !important;
}

.ant-collapse-header {
	@apply p-0 flex flex-row !important;
}

.text-disabled {
	color: var(--textColorSecondary) !important;
}

.flow div * {
	@apply text-sm;
	/* @apply border-2 border-cyan-400; */
}

.sub-flow div {
	/* @apply border border-green-400; */
}

.flow-arrow {
	@apply w-4 h-4 text-primary;
}

.flow-block {
	@apply font-bold text-lg py-3 text-center border rounded-lg hover:shadow-lg hover:relative hover:-top-1 cursor-pointer;
}

.flow-block.success {
	@apply bg-gradient-to-br from-green-100 to-green-200 text-green-900 border-green-600;
}
.flow-block.warning {
	@apply bg-gradient-to-br from-yellow-100 to-yellow-200 text-yellow-900 border-yellow-600;
}
.flow-block.danger {
	@apply bg-gradient-to-br from-red-100 to-red-200 text-red-900 border-red-600;
}

.ant-collapse-header-text {
	@apply text-lg font-bold text-sky-800 !important;
}

.ant-collapse-content {
	@apply bg-sky-100;
}

.ant-checkbox {
	@apply border-2 border-slate-400 !important;
}

.ant-transfer {
	@apply mx-auto sm:max-w-full !important;
}

.ant-transfer-list {
	@apply sm:w-1/2 !important;
}

.ant-transfer-operation .ant-btn {
	@apply bg-slate-600 text-white hover:bg-slate-500 hover:text-white;
}

.anticon-delete {
	@apply text-red-500;
}

#workflow-config .ant-card-head {
	@apply min-h-0 max-h-24 !important;
}
#workflow-config .ant-card-head .ant-card-head-title {
	@apply p-0.5 !important;
}

#workflow-config .ant-card-body {
	@apply h-full !important;
}

.wc-ok-slider .ant-slider-track {
	@apply bg-gradient-to-br from-green-100 to-green-200 !important;
}

.wc-attention-slider .ant-slider-track {
	@apply bg-gradient-to-br from-yellow-100 to-yellow-200 !important;
}

.wc-urgent-slider .ant-slider-track {
	@apply bg-gradient-to-br from-red-100 to-red-200 !important;
}

.wc-text .ant-slider-mark-text {
	@apply ml-8 !important;
}

.wc-ok-handle .ant-slider-handle {
	@apply border-green-600 !important;
}

.wc-attention-handle .ant-slider-handle {
	@apply border-yellow-600 !important;
}

.wc-urgent-handle .ant-slider-handle {
	@apply border-red-600 !important;
}

.ant-card-bordered {
	@apply border-slate-300 rounded-lg overflow-hidden hover:overflow-y-auto;
}

.ant-card-head {
	@apply subpixel-antialiased border-slate-300 rounded-t-lg bg-header text-white !important;
}

.ant-card-body {
	@apply pl-2 pb-0 !important;
}

.ant-descriptions-item-label {
	@apply subpixel-antialiased text-base font-bold text-header !important;
}

.ant-descriptions-item-content {
	@apply mt-1 text-sm;
}

.gl-descriptions .ant-descriptions-item-label {
	@apply text-xs py-1 !important;
}

.gl-descriptions .ant-descriptions-item-content {
	@apply text-xs py-1 !important;
}

.nav-bar-container {
	@apply bg-header opacity-100 z-50 !important;
}

.nav-bar-menu {
	@apply bg-header border-none z-50 !important;
}

.logo-container {
	@apply bg-white !important;
}

.table-row-light {
	background-color: #ffffff;
}
.table-row-dark {
	background-color: #fbfbfb;
}

.table-row-attention {
	@apply bg-orange-100 !important;
}

.table-row-attention .ant-table-cell-row-hover {
	@apply bg-orange-100 !important;
}

.table-row-attention .ant-table-column-sort {
	@apply bg-orange-100 !important;
}

.ant-steps-item-icon {
	/* @apply bg-green-500 !important; */
}
.ant-steps-finish-icon {
	/* @apply stroke-white text-white font-extrabold !important; */
}
.ant-layout-has-sider {
	/* @apply w-40 !important; */
}

.ant-table {
	@apply rounded-lg shadow overflow-hidden !important;
}

.payToBank {
	@apply mb-5 rounded-lg bg-gradient-to-b from-sky-100 to-sky-200  p-5 shadow border-l border-t border-slate-300;
}

.detail-grid {
	@apply grid grid-cols-2 gap-4;
}

.detail-grid label {
	@apply font-bold text-sky-800;
}

/* TAGS */

span[class^='tag-'] {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: 'tnum';
	display: inline-block;
	height: auto;
	margin-right: 8px;
	padding: 0 7px;
	font-size: 12px;
	line-height: 20px;
	white-space: nowrap;
	border-radius: 2px;
	opacity: 1;
	transition: all 0.3s;
}

.batch-name-cell {
	@apply cursor-pointer underline font-bold text-sky-500 text-xs !important;
}

.tag-Status_Error,
.tag-WEI_AutoPostStatus_Denied {
	@apply text-white bg-red-600;
}

.tag-Status_Posted,
.tag-Status_Complete,
.tag-WEI_AutoPostStatus_Approved {
	@apply bg-green-200 border border-green-700 text-green-700 font-bold border-0 text-center;
}

.tag-Status_Ready_To_Print,
.tag-Status_Processed_Conversion,
.tag-Status_Sent_To_Bank {
	@apply bg-cyan-200 border border-cyan-500 text-cyan-800 text-center;
}

.tag-Status_Created {
	@apply bg-slate-100 border border-slate-500 text-slate-800 text-center;
}

.tag-Status_Pending,
.tag-WEI_AutoPostStatus_Pending {
	color: #096dd9;
	background: #e6f7ff;
	border-color: #91d5ff;
}

.tag-Status_Submitted,
.tag-Status_User_Submitted,
.tag-Status_Submitted_Conversion,
.tag-Status_Review,
.tag-Status_Batch_Queued {
	@apply bg-amber-200 border-amber-500 text-amber-800 border-0 text-center;
}

.tag-Status_Preparing {
	color: #096dd9;
	background: #e6f7ff;
	border-color: #91d5ff;
	@apply border border-solid;
}

.tag-Status_Processing_Conversion {
	@apply bg-pink-100 border-pink-500 text-pink-800 border-0 text-center;
}

.tag-Status_Corrected {
	@apply bg-purple-200 border border-purple-500 text-purple-800 border-0 text-center;
}

.tag-Status_Manual,
.tag-Status_Currency_Conversion {
	@apply bg-amber-100 border border-amber-400 text-amber-700 text-center;
}

.tag-Status_Autodraft {
	@apply bg-lime-200 border border-lime-500 text-lime-800 border-0 text-center;
}

.tag-Status_Bank_Accepted {
	@apply bg-green-100 border border-green-400 text-green-800 text-center;
}

span[class^='tag-BatchName'] {
	@apply font-bold text-sky-500 underline cursor-pointer border-0;
}

.basic-header-title {
	margin-right: 12px;
	margin-bottom: 0;
	color: rgba(0, 0, 0, 0.85);
	font-weight: 600;
	font-size: 20px;
	line-height: 32px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.basic-sub-title {
	margin-right: 12px;
	color: rgba(0, 0, 0, 0.45);
	font-size: 14px;
	line-height: 1.5715;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.xs-descriptions .ant-descriptions-item-label,
.xs-descriptions .ant-descriptions-item-content {
	@apply text-xs !important;
}
.xs-descriptions .ant-descriptions-item-label {
	@apply -mt-0.5 !important;
}
.xs-descriptions .ant-descriptions-item-content {
	@apply -mt-0.5;
}

.base-descriptions .ant-descriptions-item-label,
.base-descriptions .ant-descriptions-item-content {
	@apply text-base !important;
}
.base-descriptions .ant-descriptions-item-label {
	@apply -mt-0.5 !important;
}
.base-descriptions .ant-descriptions-item-content {
	@apply -mt-0.5;
}

.ant-picker-range {
	@apply rounded h-[44px] -mt-0.5 !important;
}

.data-grid-headers {
	@apply font-bold mb-0 rounded-t-lg;
	color: #7dd3fc;
	background-color: var(--weiBrandDarkBlue);
}

.data-grid {
	@apply grid gap-x-4 px-4;
}
.data-grid div {
	@apply flex flex-col justify-center items-start py-2 w-auto;
}

.data-grid div.controls {
	@apply grid grid-cols-2 gap-x-4 w-72 justify-items-end;
}

/* 
FLIP CARD
The flip card container - 
set the width and height to whatever you want. We have added the border property to demonstrate that the flip itself goes out of the box on hover (remove perspective if you don't want the 3D effect */
.flip-card {
	background-color: transparent;
	border: 1px solid #f1f1f1;
	perspective: 1000px; /* Remove this if you don't want the 3D effect */
}

/* This container is needed to position the front and back side */
.flip-card-inner {
	position: relative;
	width: 100%;
	height: 100%;
	text-align: center;
	transition: transform 0.8s;
	transform-style: preserve-3d;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-card-active .flip-card-inner {
	transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-card-front,
.flip-card-back {
	position: absolute;
	width: 100%;
	height: 100%;
	-webkit-backface-visibility: hidden; /* Safari */
	backface-visibility: hidden;
}

/* Style the front side (fallback if image is missing) */
.flip-card-front {
	background-color: #bbb;
	color: black;
}

/* Style the back side */
.flip-card-back {
	background-color: dodgerblue;
	color: white;
	transform: rotateY(180deg);
}

.filter-applied-btn {
	@apply cursor-default bg-green-100 border border-green-400 text-green-800 hover:bg-green-100 hover:border-green-400 hover:text-green-800 focus:bg-green-100 focus:border-green-400 focus:text-green-800 !important;
}

.file-details div {
	@apply flex;
}

.file-details-label {
	@apply flex-none w-14 font-bold;
}

.file-details-muted {
	@apply text-slate-400 text-[10px];
}

.headline {
	@apply font-bold text-lg text-slate-500;
}

.note {
	@apply my-5 border-b border-slate-200 pb-4;
}

.note-message {
	@apply text-bc-green text-base whitespace-pre-wrap mb-4 overflow-auto w-[90%] !important;
}

.org-select {
	width: 300px;
}

.org-select .ant-select-arrow {
	@apply -mt-5 -mr-0.5 text-black !important;
}

.org-select .ant-select-selector {
	@apply mt-3 bg-purple-200 !important;
}

.org-select .ant-select-selection-item {
	@apply text-slate-800 font-bold;
}

.payment-rpt-selected-row .ant-table-cell {
	@apply bg-green-100 text-green-800 hover:bg-green-100 hover:text-green-800 focus:bg-green-100 focus:text-green-800 !important;
}
